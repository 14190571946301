<template>
  <div class="footer">
    <div class="footer_top">
      <div class="container top_container">
        <show
          :styles="{ top: '2em', left: '60em' }"
          :moveData="{ left: '1em', time: '.8s' }"
        >
          <div class="left_text">
            <p class="title">公司简介</p>
            <font color="#cacaca">
              成都领氪科技有限公司是一家智慧城市、公用事业物联网及智能化方案提供商，致力于推动AIoT<br />技术在城市及公用事业的应用与落地，以帮助政府、市政及公用企事业单位提升公共资源的管控<br />能力和智能化水平。公司聚焦水务、燃气、电力等市政与公用事业领域，提供从智能设备、大数<br />据、AIoT应用开发到智能化运维的产品和服务。
            </font>
          </div>
        </show>
        <show
          :styles="{ top: '2em', left: '60em' }"
          :moveData="{ left: '42em', time: '.9s' }"
        >
          <div class="right_contact">
            <p class="title">联系方式</p>
            <div class="contacts">
              <div>技术支持：support@ilingke.cn</div>
              <div>电话：028-8518 6891</div>
              <div class="share">
                <p>分享至：</p>
                <!-- <div class="img_text" @click="shareToWx()">
                  <p class="wx icon"></p>
                  <p>微信</p>
                </div> -->
                <div class="img_text" @click="shareToSina()">
                  <p class="wb icon"></p>
                  <p style="width: 5em !important">新浪微博</p>
                </div>
              </div>
            </div>
          </div>
        </show>
      </div>
    </div>
    <div class="container footer_bottom">
      <img
        class="logo"
        @click="toPage()"
        width="80"
        src="../../assets/images/logos/logo_footer.png"
        alt=""
      />
      <div class="info">
        ©2020 公用事业智能物联网（AIoT）方案提供商.成都领氪科技有限公司
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener"
          >蜀ICP备20006101号-1&nbsp;</a
        >
        <a
          class="mesmerize-theme-link"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010702001810"
          target="_blank"
          rel="noopener"
          ><img src="../../assets/images/gongan.png" />川公网安备51010702001810号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    //分享到微信
    shareToWx() {},
    //分享到新浪微博
    shareToSina() {
      this.message = String(location.href);
      var url = `https://service.weibo.com/share/share.php?url=
        ${encodeURIComponent(this.message)}&title=【成都领氪科技】`;
      window.open(url);
    },
    toPage() {
      sessionStorage.setItem("path", '1');
      this.$router.push('/home');
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  height: 264px;
  background-color: #293646;
  .footer_top {
    height: 200px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #364355;
  }
  .top_container {
    height: 100%;
    display: flex;
    position: relative;
    & > div {
      top: 20px;
    }
  }
  .left_text {
    width: 32em;
    font {
      font-size: 12px;
    }
  }
  .title {
    color: #fff;
    font-size: 18px;
    margin-bottom: 20px;
  }
  .right_contact {
    .contacts {
      font-size: 12px;
      color: #cacaca;
      div {
        margin-top: 20px;
      }
    }
    .share {
      display: flex;
      p {
        width: 60px;
        height: 26px;
        line-height: 26px;
      }
      div {
        cursor: pointer;
        // position: relative;
      }
    }
  }
  .footer_bottom {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7a7a7a;
  }
  .info {
    font-size: 12px;
    a {
      color: #03a9f4;
    }
  }
  .img_text {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    margin-right: 10px !important;
    p {
      width: 2.8em !important;
    }
  }
  .icon {
    width: 2.2em !important;
    height: 2.2em;
    transform: scale(0.8);
  }
  .wx {
    background: url("../../assets/images/all_icons.png") -538px -682px no-repeat;
  }
  .wb {
    background: url("../../assets/images/all_icons.png") -502px -682px no-repeat;
  }
  .logo {
    cursor: pointer;
  }
}
</style>
