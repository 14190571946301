<template>
  <div class="header container">
    <div class="logo_name" @click="toPage({ path: '/home', index: '1' })">
      <img src="../../assets/images/logos/logo.png" width="138" height="63" alt="" />
      <p class="title">领氪科技</p>
    </div>
    <el-menu
     :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <template v-for="e in menus" :index="e.index">
        <el-menu-item
          v-if="!e.child"
          :key="e.index"
          @click="$router.push(e.path)"
          :index="e.index"
          >{{ e.name }}</el-menu-item
        >
        <el-submenu v-else :key="e.index" :index="e.index">
          <template slot="title">{{ e.name }}</template>
          <el-menu-item
            @click="$router.push(e2.path)"
            v-for="e2 in e.child"
            :key="e2.index"
            :index="e2.index"
            >{{ e2.name }}</el-menu-item
          >
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "",
      menus: [
        {
          name: "首页",
          index: "1",
          path: "/home",
        },
        {
          name: "核心产品",
          index: "2",
          path: "/iot",
          child: [
            {
              name: "LinkIot 物联网平台",
              index: "21",
              path: "/iot",
            },
            {
              name: "LinkView 数据可视化平台",
              index: "22",
              path: "/view",
            },
            {
              name: "LinkScada 数据监视与控制平台",
              index: "23",
              path: "/scada",
            },
            {
              name: "LinkSmart 数据挖掘平台",
              index: "24",
              path: "/smart",
            },
            {
              name: "LinkEdge 边缘计算平台",
              index: "25",
              path: "/edge",
            },
          ],
        },
        {
          name: "解决方案",
          index: "3",
          child: [
            {
              name: "智慧水务解决方案",
              index: "31",
              path: "/home/smartWater",
            },
            {
              name: "智慧燃气解决方案",
              index: "32",
              path: "/home/gas",
            },
            {
              name: "智慧电力新能源解决方案",
              index: "33",
              path: "/home/newSource",
            },
          ],
        },
        {
          name: "关于领氪",
          index: "4",
          path: "/home/aboutLink",
        },
        {
          name: "联系我们",
          index: "5",
          path: "/home/contactUs",
        },
      ],
    };
  },

  methods: {
    handleSelect(e) {
      this.activeIndex = e;
    },
    setOldSelect() {
      sessionStorage.setItem("path", this.activeIndex);
    },
    toPage(e) {
      sessionStorage.setItem("path", e.index);
      this.$router.push(e.path);
    },
    parentSetValue(e) {
      this.activeIndex = e.value;
    },
  },
  mounted() {

   
    this.activeIndex = sessionStorage["path"] ? sessionStorage["path"] : "1";
     console.log("哈哈哈",this.activeIndex);
    window.addEventListener("beforeunload", this.setOldSelect);
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo_name {
    display: flex;
    align-items: center;
    cursor: pointer;
    .title {
      text-indent: 10px;
      font-size: 1.7rem;
      font-weight: bold;
    }
  }
  .el-menu-item {
    padding: 0 1.2rem;
  }
  .el-menu-item:hover {
    border-bottom: 2px solid #409eff !important;
    color: #409eff !important;
  }
  .el-menu.el-menu--horizontal {
    border: none;
  }
}
.is-active {
  color: #409eff !important;
}
</style>
