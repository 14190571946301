<template>
  <div class="home">
    <!-- header -->
    <headers ref="header" />
    <!-- main -->
    <router-view />
    <!-- footer -->
    <footers v-if="show" />
  </div>
</template>

<script>
import headers from "../../components/page/header.vue";
import footers from "../../components/page/footer.vue";
export default {
  components: {
    headers,
    footers,
  },
  data() {
    return {
      show: true,
      activeIndex: "",
    };
  },
  mounted() {
    window.addEventListener("beforeunload", () => {
      this.activeIndex = sessionStorage["path"];
    });
    this.activeIndex = sessionStorage["path"];
    window.addEventListener("setItemEvent", (e) => {
      this.show = false;
      if (e.value != this.activeIndex) {
        this.$refs.header.parentSetValue(e);
        window.scrollTo(0, 0);
      }
      this.$nextTick(() => {
        this.show = true;
      });
    });
  },
};
</script>
